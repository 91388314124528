import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers, faPlus, faUser, faList, faFire } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';


const Sidebar = ({ onOrganizeGroupClick, onIndividualIntentionClick,onLightACandleClick }) => {
  const navigate = useNavigate();
  const [showButtons, setShowButtons] = useState(false);

  useEffect(() => {
    const savedShowButtons = localStorage.getItem('showButtons') === 'true';
    setShowButtons(savedShowButtons);
  }, []);

  useEffect(() => {
    localStorage.setItem('showButtons', showButtons);
  }, [showButtons]);

  const handleNavigate = (path) => {
    navigate(path);
  };

  const toggleButtons = () => {
    setShowButtons(!showButtons);
  };

 

  

  return (
    <div className="absolute left-4 top-1/2 transform -translate-y-1/2 flex flex-col space-y-2 z-10">
      <div className="flex flex-col space-y-2">
        <button
          onClick={() => handleNavigate('/')}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline flex items-center space-x-2 transition transform hover:scale-105 w-full"
        >
          <FontAwesomeIcon icon={faUsers} />
          <span>Group Intention</span>
        </button>
        <button
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded cursor-pointer flex items-center space-x-2 transition transform hover:scale-105 w-full"
          onClick={onOrganizeGroupClick}
        >
          <FontAwesomeIcon icon={faPlus} />
          <span>Organize Group Intention</span>
        </button>
        <button
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded cursor-pointer flex items-center space-x-2 transition transform hover:scale-105 w-full"
          onClick={onIndividualIntentionClick}
        >
          <FontAwesomeIcon icon={faUser} />
          <span>Individual Intention</span>
        </button>
        <button
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded cursor-pointer flex items-center space-x-2 transition transform hover:scale-105 w-full"
          onClick={() => handleNavigate('/Allintentions')}
        >
          <FontAwesomeIcon icon={faList} />
          <span>All Intentions</span>
        </button>
        <button
          onClick={toggleButtons}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline flex items-center space-x-2 transition transform hover:scale-105 w-full"
        >
          <FontAwesomeIcon icon={faFire} />
          <span>Light a Candle</span>
        </button>
      </div>

      {showButtons && (
        <div className="absolute top-full left-0 flex flex-col space-y-2 mt-2 w-full">
          <button 
          
            onClick={onLightACandleClick}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline flex items-center space-x-2 transition transform hover:scale-105 w-full"
          >
            <FontAwesomeIcon icon={faPlus} />
            <span>Register</span>
          </button>
          <button
            onClick={() => handleNavigate('/lightacandle')}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline flex items-center space-x-2 transition transform hover:scale-105 w-full"
          >
            <span>Display</span>
          </button>
        </div>
      )}

     </div>
  );
};

export default Sidebar;
