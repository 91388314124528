import React, { createContext, useState } from 'react';

export const GlobeContext = createContext();

export const GlobeProvider = ({ children }) => {
  const [intentionDesc, setIntentionDesc] = useState('');
  const [groupEventCount, setGroupEventCount] = useState(0);
  const [globeData, setGlobeData] = useState([]); // Ensure this is initialized as an array
  const [intentions, setIntentions] = useState([]);
  const [intentionInfo, setIntentionInfo] = useState([]);
  const [LightACandleInfo, setLightACandleInfo] = useState([]);

  return (
    <GlobeContext.Provider value={{ intentionDesc, setIntentionDesc, groupEventCount, setGroupEventCount, globeData, setGlobeData, intentions, setIntentions, intentionInfo, setIntentionInfo, LightACandleInfo, setLightACandleInfo }}>
      {children}
    </GlobeContext.Provider>
  );
};
