import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const OrganizeGroupIntention = ({ handleClose }) => {
  const [intentions, setIntentions] = useState([]);
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [formData, setFormData] = useState({
    IntentionID: '',
    IntentionDesc: '',
    country: '',
    CityID: '',
    startDate: '',
    endDate: ''
  });
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [openCaptchaDialog, setOpenCaptchaDialog] = useState(false);
  const [captchaValue, setCaptchaValue] = useState('');
  const [generatedCaptcha, setGeneratedCaptcha] = useState('');

  useEffect(() => {
    // Fetch intentions and countries on component mount
    axios.get('http://localhost:8000/api/getIntentions')
      .then(response => {
        setIntentions(response.data);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching intentions', error);
        setLoading(false);
      });

    axios.get('http://localhost:8000/api/getCountries')
      .then(response => {
        setCountries(response.data);
      })
      .catch(error => {
        console.error('Error fetching countries', error);
      });

    generateCaptcha(); // Generate initial captcha
  }, []);
  const handleCountryChange = (e) => {
    const countryId = e.target.value;
    setFormData({ ...formData, country: countryId });
    axios.get(`http://localhost:8000/api/getCities/${countryId}`)
      .then(response => {
        setCities(response.data);
      })
      .catch(error => {
        console.error('Error fetching cities', error);
      });
  };

  const generateCaptcha = () => {
    const captcha = Math.random().toString(36).slice(2, 8);
    setGeneratedCaptcha(captcha);
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleCloseCaptchaDialog = () => {
    setOpenCaptchaDialog(false);
  };

  const handleCaptchaChange = (e) => {
    setCaptchaValue(e.target.value);
  };

  const handleOpenCaptchaDialog = () => {
    setOpenCaptchaDialog(true);
    generateCaptcha();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleOpenCaptchaDialog();
  };

  const handleSubmitWithCaptcha = () => {
    if (captchaValue.toLowerCase() === generatedCaptcha.toLowerCase()) {
      axios.post('http://localhost:8000/api/registEvent', formData)
        .then(() => {
          setFormData({
            IntentionID: '',
            IntentionDesc: '',
            country: '',
            CityID: '',
            startDate: '',
            endDate: ''
          });
          generateCaptcha();
          toast.success('Registered Successfully'); // Notify success
          setOpenCaptchaDialog(false); // Close captcha dialog
          handleClose(); // Close the OrganizeGroupIntention dialog
        })
        .catch(error => {
          if (error.response && error.response.data === 'captcha error') {
            toast.error('Wrong Captcha');
          } else {
            console.error('Error registering event', error);
            toast.error('Error registering event');
          }
        });
    } else {
      toast.error('Incorrect Captcha. Please try again.');
    }
  };

  const handleCancel = () => {
    handleClose(false);
  };
  return (
    <div>
      <form onSubmit={handleSubmit} className="w-full max-w-lg mx-auto bg-white p-8 rounded-lg shadow-md">
        <div className="grid grid-cols-2 gap-4">
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="IntentionID">
              Intention
            </label>
            <div className="relative">
              <select
                name="IntentionID"
                value={formData.IntentionID}
                onChange={handleChange}
                required
                className="block appearance-none w-full bg-white border border-gray-300 hover:border-gray-500 px-4 py-2 pr-8 rounded leading-tight focus:outline-none focus:shadow-outline"
              >
                <option value="" disabled>Select Intention</option>
                {intentions.map((intention, index) => (
                  <option
                    key={intention.IntentionId}
                    value={intention.IntentionId}
                    className={index % 2 === 0 ? 'bg-gray-200' : 'bg-white'}
                  >
                    {intention.IntentionName}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="IntentionDesc">
              Intention Description
            </label>
            <input
              name="IntentionDesc"
              type="text"
              value={formData.IntentionDesc}
              onChange={handleChange}
              required
              className="w-full h-20 px-8 py-6 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
              />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="country">
              Country
            </label>
            <div className="relative">
              <select
                name="country"
                value={formData.country}
                onChange={handleCountryChange}
                required
                className="block appearance-none w-full bg-white border border-gray-300 hover:border-gray-500 px-4 py-2 pr-8 rounded leading-tight focus:outline-none focus:shadow-outline"
              >
                <option value="" disabled>Select Country</option>
                {countries.map((country, index) => (
                  <option
                    key={country.CountryID}
                    value={country.CountryID}
                    className={index % 2 === 0 ? 'bg-gray-200' : 'bg-white'}
                  >
                    {country.CountryName}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="CityID">
              City
            </label>
            <div className="relative">
              <select
                name="CityID"
                value={formData.CityID}
                onChange={handleChange}
                required
                className="block appearance-none w-full bg-white border border-gray-300 hover:border-gray-500 px-4 py-2 pr-8 rounded leading-tight focus:outline-none focus:shadow-outline"
              >
                <option value="" disabled>Select City</option>
                {cities.map((city, index) => (
                  <option
                    key={city.CityID}
                    value={city.CityID}
                    className={index % 2 === 0 ? 'bg-gray-200' : 'bg-white'}
                  >
                    {city.CityName}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="startDate">
              Event Start Date and Time
            </label>
            <input
              name="startDate"
              type="datetime-local"
              value={formData.startDate}
              onChange={handleChange}
              required
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="endDate">
              Event End Date and Time
            </label>
            <input
              name="endDate"
              type="datetime-local"
              value={formData.endDate}
              onChange={handleChange}
              required
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
            />
          </div>
        </div>
        <div className="flex items-center justify-between mt-4">
          <button
            type="submit"
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            Organize Group Intention
          </button>
          <button
            type="button"
            onClick={handleCancel}
            className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            Cancel
          </button>
        </div>
      </form>

      {/* Captcha Dialog */}
      {openCaptchaDialog && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-900 bg-opacity-50">
          <div className="bg-white p-8 rounded-lg shadow-md">
            <div className="mb-4">
              <h2 className="text-xl font-bold mb-4">Verify Captcha</h2>
              <p className="mb-2">Enter the following Captcha to proceed:</p>
              <div className="mb-2 text-center text-lg font-mono bg-black text-gray-300 py-2 px-4 rounded line-through">
                  {generatedCaptcha}
                </div>    
                <input
                type="text"
                value={captchaValue}
                onChange={handleCaptchaChange}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500 mb-4"
              />
               {captchaValue.toLowerCase() !== generatedCaptcha.toLowerCase() && captchaValue !== '' ? (
                    <p className="text-red-500 text-sm">Incorrect Captcha. Please try again.</p>
                  ) : null }
            </div>
            <div className="flex items-center justify-between">
              <button
                onClick={generateCaptcha}
                className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline m-1"
              >
                Regenerate Captcha
              </button>
              <button
                onClick={handleSubmitWithCaptcha}
                className={`bg-blue-500 hover:bg-blue-700 text-white font-bold m-1 py-2 px-4 rounded focus:outline-none focus:shadow-outline ${
                  captchaValue.toLowerCase() !== generatedCaptcha.toLowerCase() ? 'opacity-50 cursor-not-allowed' : ''
                }`}
                disabled={captchaValue.toLowerCase() !== generatedCaptcha.toLowerCase()}
              >
                Submit
              </button>
              <button
                onClick={handleCloseCaptchaDialog}
                className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}

      
    </div>
  );
};

export default OrganizeGroupIntention;
