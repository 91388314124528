import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import GlobeComponent from '../components/GlobeComponent';
import Sidebar from '../components/Sidebar';
import OrganizeGroupIntention from './OrganizeGroupIntention';
import IndividualIntention from './IndividualIntention';
import { GlobeContext } from '../contexts/GlobeContext';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LightCandleForm from '../components/LightACandleForm';

const GroupIntention = () => {
  const { setIntentionDesc, setGroupEventCount, setGlobeData } = useContext(GlobeContext);

  const [isOrganizeDialogOpen, setIsOrganizeDialogOpen] = useState(false);
  const [isIndividualDialogOpen, setIsIndividualDialogOpen] = useState(false);
  const [groupEvents, setGroupEvents] = useState([]);
  const [selectedGroupEvent, setSelectedGroupEvent] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [loadingCities, setLoadingCities] = useState(false);

  const [Description, setDescription] = useState();
  const [IntCount, setIntCount] = useState();
  const [IsLightCandleDialogOpen, setIsLightCandleDialogOpen] = useState(false);

  useEffect(() => {
    fetchCountries();
    fetchGroupEvents();

    // Clean up function
    return () => {
      resetSelections(); // Reset local state
      setGlobeData(null); // Reset globe data in context
    };
  }, []);

  const fetchCountries = () => {
    axios.get('http://localhost:8000/api/getCountries')
      .then((response) => {
        setCountries(response.data);
      })
      .catch((error) => console.log(error));
  };

  const fetchGroupEvents = () => {
    axios.get('http://localhost:8000/api/getAllGroupEvents')
      .then((response) => {
        setGroupEvents(response.data);
      })
      .catch((error) => console.log(error));
  };

  const fetchGroupEventInfo = (groupEventId) => {
    axios.get(`http://localhost:8000/api/getGreenlineInfo/${groupEventId}`)
      .then((response) => {
        const payload = response.data.payload;
        updateGroupEventInfo(payload);
        toast.success(`Successfully loaded group event information ${groupEventId}`);
      })
      .catch((error) => {
        console.log(error);
        toast.error('Failed to load group event information');
      });
  };

  const updateGroupEventInfo = (payload) => {
    setIntentionDesc(payload.intentionInfo.intentiondesc);
    setGroupEventCount(payload.intentionInfo['count(GroupEventId)']);
    setDescription(payload.intentionInfo.intentiondesc);
    setIntCount(payload.intentionInfo['count(GroupEventId)']);
    onPayloadReceived(payload);
  };

  const handleGroupEventChange = (event) => {
    const value = event.target.value;
    const option = groupEvents.find((e) => e.GroupEventId.toString() === value);
    if (option) {
      setSelectedGroupEvent(option);
      fetchGroupEventInfo(option.GroupEventId);
    } else {
      setSelectedGroupEvent(null);
    }
  };

  const handleCountryChange = (event) => {
    const value = event.target.value;
    const option = countries.find((country) => country.CountryID.toString() === value);
    if (option) {
      setLoadingCities(true);
      fetchCities(option.CountryID);
      setSelectedCountry(option);
    } else {
      setSelectedCountry(null);
      setCities([]);
    }
  };

  const fetchCities = (countryId) => {
    axios.get(`http://localhost:8000/api/getCities/${countryId}`)
      .then((response) => {
        setCities(response.data);
        setLoadingCities(false);
      })
      .catch((error) => {
        console.log(error);
        setLoadingCities(false);
      });
  };

  const handleCityChange = (event) => {
    const value = event.target.value;
    const option = cities.find((city) => city.CityID.toString() === value);
    setSelectedCity(option);
  };

  const handleSubmit = () => {
    if (selectedCountry && selectedCity && selectedGroupEvent) {
      axios.post('http://localhost:8000/api/groupIntention', {
        GroupEventId: selectedGroupEvent.GroupEventId,
        IntentionID: selectedGroupEvent.IntentionID,
        IntentionDesc: selectedGroupEvent.IntentionDesc,
        CountryID: selectedCountry.CountryID,
        CityID: selectedCity.CityID
      })
        .then((response) => {
          fetchGroupEventInfo(selectedGroupEvent.GroupEventId);
          resetSelections();
          toast.success('Submit Group Intention success');
        })
        .catch((error) => {
          console.log(error);
          toast.error('Failed to submit Group Intention');
        });
    } else {
      toast.error('Please select all fields');
    }
  };

  const resetSelections = () => {
    setSelectedGroupEvent(null);
    setSelectedCountry(null);
    setSelectedCity(null);
  };

  const handleOpenOrganizeDialog = () => {
    setIsOrganizeDialogOpen(true);
  };

  const handleOpenIndividualDialog = () => {
    setIsIndividualDialogOpen(true);
  };

  const handleCloseOrganizeDialog = () => {
    setIsOrganizeDialogOpen(false);
    setIsIndividualDialogOpen(false);
    fetchGroupEvents();
  };

  const onPayloadReceived = (payload) => {
    setGlobeData(payload); // Update globe data in context
  };

  const handleCloseModal = () => {
    setIsLightCandleDialogOpen(false)
    
  };

  const handleOpenLightacandleDialog=()=>{
    setIsLightCandleDialogOpen(true)
  }

  return (
    <div className="relative w-screen h-screen">
      <ToastContainer position="top-right" autoClose={3000} />
      <GlobeComponent />
      <Sidebar 
        onOrganizeGroupClick={handleOpenOrganizeDialog}
        onIndividualIntentionClick={handleOpenIndividualDialog} 
        onLightACandleClick={handleOpenLightacandleDialog}
      />
      <div className="absolute bottom-4 right-4 bg-white rounded-lg p-3 shadow-lg">
        <p><b>Intention Description:</b> {Description}</p>
        <p><b>Group Event Count:</b> {IntCount}</p>
      </div>

      <div className="absolute top-2 left-1/2 transform -translate-x-1/2 flex space-x-1 p-1 bg-gray-100 rounded shadow-md cursor-pointer h-10">
        <select
          className="block py-1 px-2 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 text-xs cursor-pointer h-8 w-40"
          value={selectedGroupEvent ? selectedGroupEvent.GroupEventId : ''}
          onChange={handleGroupEventChange}
        >
          <option value="" disabled>Select Group Event</option>
          {groupEvents.map((e, index) => (
            <option
              key={e.GroupEventId}
              value={e.GroupEventId}
              className={index % 2 === 0 ? 'bg-gray-200' : 'bg-white'}
            >
              {`${e.GroupEventId}.${e.IntentionDesc}`}
            </option>
          ))}
        </select>
        <select
          className="block py-1 px-2 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 text-xs cursor-pointer h-8 w-40"
          value={selectedCountry ? selectedCountry.CountryID : ''}
          onChange={handleCountryChange}
        >
          <option value="" disabled>Select Country</option>
          {countries.map((country, index) => (
            <option
              key={country.CountryID}
              value={country.CountryID}
              className={index % 2 === 0 ? 'bg-gray-200' : 'bg-white'}
            >
              {country.CountryName}
            </option>
          ))}
        </select>
        <select
          className="block py-1 px-2 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 text-xs cursor-pointer h-8 w-40"
          value={selectedCity ? selectedCity.CityID : ''}
          onChange={handleCityChange}
          disabled={loadingCities}
        >
          <option value="" disabled>Select City</option>
          {cities.map((city, index) => (
            <option
              key={city.CityID}
              value={city.CityID}
              className={index % 2 === 0 ? 'bg-gray-200' : 'bg-white'}
            >
              {city.CityName}
            </option>
          ))}
        </select>
        <button
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-4 rounded text-xs cursor-pointer h-8 w-32 flex items-center justify-center"
          onClick={handleSubmit}
        >
          Join Group Intention
        </button>
      </div>
      {IsLightCandleDialogOpen && (
  <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-900 bg-opacity-75">
    <LightCandleForm handleCloseModal={handleCloseModal} />
  </div>
)}

      {isOrganizeDialogOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-900 bg-opacity-50">
          <OrganizeGroupIntention handleClose={handleCloseOrganizeDialog} />
        </div>
      )}
      {isIndividualDialogOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-900 bg-opacity-50">
          <IndividualIntention handleClose={handleCloseOrganizeDialog} />
        </div>
      )}
      
    </div>
  );
};

export default GroupIntention;
