import React, { useEffect, useState, useRef, useContext } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import GlobeComponent from '../components/GlobeComponent';
import Sidebar from '../components/Sidebar';
import OrganizeGroupIntention from '../pages/OrganizeGroupIntention';
import IndividualIntention from '../pages/IndividualIntention';
import { GlobeContext } from '../contexts/GlobeContext';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LightCandleForm from '../components/LightACandleForm';

const AllIntentions = () => {
  
  const [loading, setLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(true);
  const navigate = useNavigate();
  const isMounted = useRef(false);
  const [isOrganizeDialogOpen, setIsOrganizeDialogOpen] = useState(false);
  const [isIndividualDialogOpen, setIsIndividualDialogOpen] = useState(false);
  const [IsLightCandleDialogOpen, setIsLightCandleDialogOpen] = useState(false);

  const { intentions, setIntentions, setIntentionInfo } = useContext(GlobeContext);

  // Define fetchData function outside useEffect
  const fetchData = async () => {
    try {
      const [intentionsResponse, intentionInfoResponse] = await Promise.all([
        axios.get('http://localhost:8000/api/getIntentions'),
        axios.get('http://localhost:8000/api/getIntentionInfo')
      ]);
      setIntentions(intentionsResponse.data);
      setIntentionInfo(intentionInfoResponse.data);
      console.log('intentionsResponse.data',intentionsResponse.data)
      console.log('intentionInfoResponse.data',intentionInfoResponse.data)
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;
      fetchData(); // Call fetchData function here
    }
  }, [setIntentions, setIntentionInfo]);

  const handleOpenOrganizeDialog = () => {
    setIsOrganizeDialogOpen(true);
  };

  const handleOpenIndividualDialog = () => {
    setIsIndividualDialogOpen(true);
  };

  const handleCloseOrganizeDialog = () => {
    fetchData(); // Call fetchData function here
    setIsOrganizeDialogOpen(false);
    setIsIndividualDialogOpen(false);
  };

  const handleCloseIndividualDialog = () => {
    fetchData(); // Call fetchData function here
    setIsIndividualDialogOpen(false);
  };
  const handleCloseModal = () => {
    setIsLightCandleDialogOpen(false)
    
  };

  const handleOpenLightacandleDialog=()=>{
    setIsLightCandleDialogOpen(true)
  }
    

  if (loading) {
    return <div className="flex justify-center items-center h-screen"><div className="loader"></div></div>;
  }

  return (
    <div className="relative flex h-screen bg-gray-100">
      <div className="absolute inset-0 flex-1 justify-center items-center">
        <GlobeComponent />
      </div>
      <Sidebar 
        onOrganizeGroupClick={handleOpenOrganizeDialog}
        onIndividualIntentionClick={handleOpenIndividualDialog} 
        onLightACandleClick={handleOpenLightacandleDialog}
      />
      {isOpen && (
        <div className="absolute right-4 top-1/2 transform -translate-y-1/2 p-4">
          <div className="shadow-md rounded-lg overflow-hidden w-80">
            <table className="w-full bg-white cursor-pointer">
              <thead>
                <tr>
                  <th className="py-2 px-4 bg-gray-800 text-white">Intention Name</th>
                  <th className="py-2 px-4 bg-gray-800 text-white">Number of People Intended</th>
                </tr>
              </thead>
              <tbody>
                {intentions.map((intention, index) => (
                  <tr key={index} className="bg-gray-100">
                    <td className="py-2 px-4 border-b border-gray-200">{intention.IntentionName}</td>
                    <td className="py-2 px-4 border-b border-gray-200">{intention.IntentionCount}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
      {isOrganizeDialogOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-900 bg-opacity-50">
          <OrganizeGroupIntention handleClose={handleCloseOrganizeDialog} />
        </div>
      )}
      {isIndividualDialogOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-900 bg-opacity-50">
          <IndividualIntention handleClose={handleCloseIndividualDialog} />
        </div>
      )}
      {IsLightCandleDialogOpen&& (
        <div className="inset-0 flex items-center justify-center z-50 bg-gray-900">
      <LightCandleForm handleCloseModal={handleCloseModal}/>
      </div>
      )}

      <ToastContainer />
    </div>
  );
};

export default AllIntentions;
