import React, { useEffect, useRef, useContext, useMemo } from 'react';
import Globe from 'globe.gl';
import * as d3 from 'd3';
import earthImage from './assets/earth-large.jpg';
import backgroundImage from './assets/galaxy_starfield.png';
import { GlobeContext } from '../contexts/GlobeContext';
import { useLocation } from 'react-router-dom';
import websiteLogo from './assets/websitelogo.JPG';

const GlobeComponent = ({ onPointClick }) => {
  const globeRef = useRef();
  const logoRef = useRef();

  const { globeData, intentionInfo, LightACandleInfo } = useContext(GlobeContext);
  const location = useLocation();
  let world = null;

  const handlePointClick = (point) => {
    if (LightACandleInfo && LightACandleInfo.some(info => info.latitude === point.lat && info.longitude === point.lng)) {
      const clickedPointInfo = LightACandleInfo.find(info => info.latitude === point.lat && info.longitude === point.lng);
      onPointClick(clickedPointInfo);
    }
  };

  const handleLogoClick = () => {
    window.location.href = 'https://livinglibrarian.com/new/';
  };

  const calculateDistance = (lat1, lng1, lat2, lng2) => {
    const R = 6371;
    const dLat = (lat2 - lat1) * (Math.PI / 180);
    const dLng = (lng2 - lng1) * (Math.PI / 180);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(lat1 * (Math.PI / 180)) * Math.cos(lat2 * (Math.PI / 180)) *
      Math.sin(dLng / 2) * Math.sin(dLng / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c;
  };

  const countNearbyPoints = (points, maxDistance) => {
    return points.map((point, index) => {
      let count = 0;
      points.forEach((p, i) => {
        if (index !== i && calculateDistance(point.lat, point.lng, p.lat, p.lng) <= maxDistance) {
          count++;
        }
      });
      return {
        ...point,
        nearbyCount: count
      };
    });
  };

  const createPointsData = useMemo(() => (data, color) => {
    let points = data.map((item, index) => ({
      id: index,
      lat: item.latitude,
      lng: item.longitude,
      city: item.CityName || 'Unknown',
      name: item.name,
      email: item.email,
      zipcode: item.zipcode,
      country: item.country,
      color: color,
      maxR: 3,
      propagationSpeed: 1,
      repeatPeriod: 1000
    }));

    if (data === LightACandleInfo) {
      points = countNearbyPoints(points, 50);

      points.forEach(point => {
        const darkness = 1 - (point.nearbyCount + 1) * 0.1;
        point.color = `rgba(255, 0, 0, ${darkness})`;
      });
    }

    return points;
  }, [LightACandleInfo]);

  const createArcsData = useMemo(() => (users, organizers) => {
    let arcs = [];
    if (users && organizers) {
      users.forEach(user => {
        const organizer = organizers[Math.floor(Math.random() * organizers.length)];
        arcs.push({
          startLat: user.latitude,
          startLng: user.longitude,
          endLat: organizer.latitude,
          endLng: organizer.longitude,
          color: ["rgb(147, 7, 253)",` rgba(255, 0, 0, 0.3)`],
        });
      });
    }
    return arcs;
  }, []);

  useEffect(() => {
    const resizeGlobe = () => {
      if (world) {
        world.width(globeRef.current.clientWidth).height(globeRef.current.clientHeight);
      }
    };

    const updateData = () => {
      let pointsData = [];
      let arcsData = [];
      const uniquePoints = new Set();

      if (location.pathname === '/') {
        if (globeData?.Organizer) {
          const category1Points = createPointsData(globeData.Organizer, 'red');
          category1Points.forEach(point => {
            const key = `${point.lat}-${point.lng}`;
            if (!uniquePoints.has(key)) {
              uniquePoints.add(key);
              pointsData.push(point);
            }
          });
        }

        if (globeData?.indIntentionInfo) {
          const category2Points = createPointsData(globeData.indIntentionInfo, 'white');
          category2Points.forEach(point => {
            const key = `${point.lat}-${point.lng}`;
            if (!uniquePoints.has(key)) {
              uniquePoints.add(key);
              pointsData.push(point);
            }
          });
        }

        if (globeData?.users && globeData?.Organizer) {
          const category3Points = createPointsData(globeData.users, 'yellow');
          category3Points.forEach(point => {
            const key = ` ${point.lat}-${point.lng}`;
            if (!uniquePoints.has(key)) {
              uniquePoints.add(key);
              pointsData.push(point);
            }
          });

          arcsData = createArcsData(globeData.users, globeData.Organizer);
        }
      } else if (location.pathname === '/Allintentions') {
        if (intentionInfo) {
        
          const intentionPoints = createPointsData(intentionInfo, 'red');
          pointsData = pointsData.concat(intentionPoints);
        }
      } else if (location.pathname === '/lightacandle') {
        if (LightACandleInfo) {
          const lightacandlePoints = createPointsData(LightACandleInfo, 'red');
          pointsData = pointsData.concat(lightacandlePoints);
        }
      }

      if (pointsData.length > 0) {
        world.pointsData(pointsData)
          .labelsData(pointsData)
          .ringsData(pointsData);
      } else {
        world.pointsData([])
          .labelsData([])
          .ringsData([]);
      }

      if (arcsData.length > 0) {
        world.arcsData(arcsData);
      } else {
        world.arcsData([]);
      }
    };

    world = Globe()(globeRef.current)
      .globeImageUrl(earthImage)
      .bumpImageUrl('//unpkg.com/three-globe/example/img/earth-topology.png')
      .backgroundImageUrl(backgroundImage)
      .pointAltitude(0.0009)
      .pointRadius(0.4)
      .pointColor('color')
      .labelLat(d => d.lat)
      .labelLng(d => d.lng)
      .labelText(d => {
        if (d.nearbyCount > 0) {
          return `${d.city} (${d.nearbyCount + 1})`;
        } else {
          return d.city;
        }
      })
      .labelSize(0.7)
      .labelColor(() => 'white')
      .labelResolution(2)
      .enablePointerInteraction(true)
      .ringColor(d => d.color)
      .ringMaxRadius(d => d.maxR)
      .ringPropagationSpeed(d => d.propagationSpeed)
      .ringRepeatPeriod(d => d.repeatPeriod)
      .arcDashLength(0.03)
      .arcDashGap(0.01)
      .arcsTransitionDuration(0)
      .arcColor(e => ["rgb(147, 7, 253)",` rgba(255, 0, 0, ${intentionInfo ? (e === intentionInfo ? 0.9 : 0.075) : 0.3})`])
      .arcStroke(1)
      .arcDashAnimateTime(10000)
      .onPointClick(handlePointClick)
      .onLabelClick(handlePointClick);

    updateData();

    world.controls().autoRotate = true;
    world.controls().autoRotateSpeed = 0.1;

    // Adjust label positions to avoid overlap using force simulation
    const adjustLabels = () => {
      const labels = d3.selectAll('.globe-label');

      const nodes = labels.nodes().map(node => {
        const bbox = node.getBBox();
        return {
          node,
          x: parseFloat(node.getAttribute('x')),
          y: parseFloat(node.getAttribute('y')),
          width: bbox.width,
          height: bbox.height
        };
      });

      const simulation = d3.forceSimulation(nodes)
        .force('x', d3.forceX(d => d.x).strength(1))
        .force('y', d3.forceY(d => d.y).strength(1))
        .force('collision', d3.forceCollide().radius(d => d.width / 2 + 2))
        .stop();

      for (let i = 0; i < 120; i++) {
        simulation.tick();
      }

      labels.each((d, i) => {
        const node = nodes[i];
        d3.select(node.node)
          .attr('x', node.x)
          .attr('y', node.y);
      });
    };

    setTimeout(adjustLabels, 500); 

    window.addEventListener('resize', resizeGlobe);
    resizeGlobe();

    return () => {
      window.removeEventListener('resize', resizeGlobe);
      world.resetProps();
    };
  }, [globeData, intentionInfo, LightACandleInfo, location, createPointsData, createArcsData]);

  return (
    <div>
      <div ref={globeRef} className="absolute inset-0"></div>
      <div 
  className="absolute top-4 left-3 text-white font-bold text-4xl leading-tight border-2 border-white rounded-lg p-6 h-46 max-w-xs overflow-hidden flex items-center justify-center"
  style={{ fontFamily: 'Montserrat, sans-serif' }}
>
  <span className="break-words text-center">
    COLLECTIVE<br />CONSCIOUSNESS<br />OF THE EARTH
  </span>
</div>
      <img
        ref={logoRef}
        src={websiteLogo}
        alt="Website Logo"
        className="absolute top-4 right-4 w-30 h-16 cursor-pointer"
        onClick={handleLogoClick}
      />
    </div>
  );
};

export default GlobeComponent;
