import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';


import 'react-toastify/dist/ReactToastify.css';

import { GlobeContext } from '../contexts/GlobeContext';
import zipcodeData from '../data/ZIPCode.json'; // Import your local JSON dataset
import fire from './assets/fire.jpg';
const LightCandleForm = ({handleCloseModal}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [formData, setFormData] = useState({
      name: '',
      email: '',
      country: '',
      city: '',
      zipcode: '',
      latitude: null,
      longitude: null
    });
    const [countries, setCountries] = useState([]);
    const [cities, setCities] = useState([]);
    const [openCaptchaDialog, setOpenCaptchaDialog] = useState(false);
    const [captchaValue, setCaptchaValue] = useState('');
    const [generatedCaptcha, setGeneratedCaptcha] = useState('');
    const [error, setError] = useState('');
    const [coordinates, setCoordinates] = useState(null);
    const [zipValid, setZipValid] = useState(true); // State to track zip code validation result
    const [isOrganizeDialogOpen, setIsOrganizeDialogOpen] = useState(false);
    const [isIndividualDialogOpen, setIsIndividualDialogOpen] = useState(false);
    const { setLightACandleInfo } = useContext(GlobeContext);
    const [fetchDataTrigger, setFetchDataTrigger] = useState(false);
  
  
    useEffect(() => {
      // Fetch intentionInfo data
      axios.get('http://localhost:8000/api/userIntentions')
        .then(response => {
          setLightACandleInfo(response.data.data); // Assuming response structure is { data: [] }
          console.log('Data fetched:', response.data.data);
        })
        .catch(error => console.error('Error fetching intentions', error));
    }, [fetchDataTrigger,setLightACandleInfo]);
  
    useEffect(() => {
      axios.get('http://localhost:8000/api/getCountries')
        .then(response => {
          setCountries(response.data);
        })
        .catch(error => console.error('Error fetching countries', error));
    }, []);
  
   
    const resetFormData = () => {
      setFormData({
        name: '',
        email: '',
        country: '',
        city: '',
        zipcode: '',
        latitude: null,
        longitude: null
      });
      setCaptchaValue('');
      setGeneratedCaptcha('');
      setError('');
      setCoordinates(null);
      setZipValid(true);
    };
  
    
  
    const handleCountryChange = (e) => {
      const countryId = e.target.value;
      
      console.log('countryId',countryId)
      setFormData(prevState => ({
        ...prevState,
        country: countryId,
        city: '' // Reset city when country changes
      }));
      axios.get(`http://localhost:8000/api/getCities/${countryId}`)
        .then(response => {
          // console.log('Country response',response.data.CountryID)
          setCities(response.data);
        })
        .catch(error => console.error('Error fetching cities', error));
    };
    const handleCityChange = (e) =>{
    const CityID = e.target.value;
    console.log('CityID',CityID)
    setFormData(prevState => ({
      ...prevState,
      city: CityID,
    }));
    }
  
    const handleChange = (e) => {
      const { name, value, type, checked } = e.target;
      setFormData(prevState => ({
        ...prevState,
        [name]: type === 'checkbox' ? checked : value
      }));
    };
  
    const generateCaptcha = () => {
      const randomCaptcha = Math.random().toString(36).substring(2, 7);
      setGeneratedCaptcha(randomCaptcha);
    };
  
    const handleSubmitWithCaptcha = () => {
      if (captchaValue.toLowerCase() === generatedCaptcha.toLowerCase()) {
        handleSubmit();
        setIsOrganizeDialogOpen(false);
        setIsIndividualDialogOpen(false)
        setOpenCaptchaDialog(false);
      } else {
        toast.error('Incorrect Captcha. Please try again.');
      }
    };
  
    const handleCaptchaChange = (e) => {
      setCaptchaValue(e.target.value);
    };
  
    const handleCloseCaptchaDialog = () => {
      setOpenCaptchaDialog(false);
    };
  
    const handleOpenOrganizeDialog = () => {
      setIsOrganizeDialogOpen(true);
    };
  
    const handleOpenIndividualDialog = () => {
      setIsIndividualDialogOpen(true);
    };
  
    const handleCloseOrganizeDialog = () => {
      setIsOrganizeDialogOpen(false);
      setIsIndividualDialogOpen(false);
    };
  
  
    const handleSubmit = () => {
      // Ensure formData has CityID populated
      if (!formData.city) {
        toast.error('City is required');
        return;
      }
    
      const isValid = validateZipcode(formData.zipcode, formData.country, formData.city);
    
      // Prepare data for submission
      const dataToSend = {
        ...formData,
        CityID: formData.city, // Include CityID in the POST request
        latitude: isValid ? formData.latitude : null,
        longitude: isValid ? formData.longitude : null
      };
    console.log('dataToSend',dataToSend)
      // // Handle form submission logic here
      axios.post('http://localhost:8000/api/userIntentions', dataToSend)
        .then(response => {
          toast.success('Registered Successfully');
          setFetchDataTrigger(prev => !prev); // Toggle the state to trigger useEffect
          setIsOpen(false);
          handleCloseModal(); // Close the modal after successful form submission
          resetFormData();
        })
        .catch(error => {
          console.error('Error registering intention', error);
        });
    };
    
    const handleOpenCaptchaDialog = async (e) => {
      e.preventDefault();
    
      // Reset captcha value
      setCaptchaValue('');
    
      const isValid = validateZipcode(formData.zipcode, formData.country, formData.city);
    
      if (!isValid) {
        // If ZIP code is invalid, set latitude and longitude to null
        setFormData(prevData => ({
          ...prevData,
          latitude: null,
          longitude: null
        }));
      }
    
      generateCaptcha();
      setOpenCaptchaDialog(true);
    };
    
    
    const validateZipcode = (zipcode, countryId, CityID) => {
      const zipInfo = zipcodeData.combined_data_final.find((zip) => {
               return (
                 zip.POSTAL_CODE == zipcode && 
                 zip.CountryID == countryId && 
                 zip.CityID == CityID
               );
             }
             );
             console.log('zipInfo',zipInfo)
            if (zipInfo) {
               console.log('Valid data: country, city, and ZIP code');
             setCoordinates({ lat: zipInfo.LATITUDE, lng: zipInfo.LONGITUDE });
             console.log('lat: zipInfo.LATITUDE, lng: zipInfo.LONGITUDE', { lat: zipInfo.LATITUDE, lng: zipInfo.LONGITUDE });
             setZipValid(true);
             setFormData((prevData) => ({
               ...prevData,
               latitude: zipInfo.LATITUDE,
               longitude: zipInfo.LONGITUDE
             }));
            return true;
      } else {
        // If not valid, set latitude and longitude to null
        setFormData(prevData => ({
          ...prevData,
          latitude: null,
          longitude: null
        }));
        setZipValid(false);
        return false;
      }
    };
    
  
  
   

  return (
    <div >
   
        <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-8xl h-full overflow-y-auto flex flex-col items-center  h-screen">
          <button
            onClick={() => {
              handleCloseModal();
            }}
            className="absolute top-2 right-7 text-gray-500 hover:text-gray-700 h-18 w-20 focus:outline-none"
          >
            <FontAwesomeIcon icon={faTimes} size="2xl" />
          </button>

          <div className="mb-8">
            <h1 className="text-2xl font-bold mb-4 text-center">Light a Candle</h1>

            <div className="mb-8">
              <p className="mb-4 text-center">
                We believe that the reality we experience as individuals and collectively on this planet is 
                shaped by our consciousness. By raising our individual and collective vibrations, we have the 
                power to transform the world around us into a place of peace, love, compassion, happiness, abundance, and unity. 
                To support this transformation, We invite you to join this mission by lighting a candle, it’s as 
                simple as that. This is not just a virtual online platform; it's a symbol of our collective power to 
                transform the world by coming together in peace, love, and compassion, for each other, we can create a
                brighter future for ourselves and generations to come.
              </p>
            </div>

            <div className="bg-black text-white p-6 rounded-lg shadow-lg w-full max-w-8xl overflow-y-auto flex items-center bg-black-600">
              {/* Image */}
              <div className="mr-2">
                <img
                  src={fire}
                  alt="Lit Torch"
                  className="h-90 w-auto object-contain"
                />
              </div>

              {/* Content */}
              <div>
                <h2 className="text-xl font-semibold mb-2">We all agree that</h2>
                <p className="mb-4">
                  ⚪ We are all spiritual beings having human experience. The core principles of peace, love, unity, and compassion form the foundation of us.
                </p>
                <p className="mb-4">
                  ⚪ Regardless of race, religion, culture, or nationality, we are all connected by our shared humanity and our fundamental desire is to live in peace and harmony.
                </p>
                <p className="mb-4">
                  ⚪ We serve as a beacon of hope, demonstrating humanity's potential for cooperation, understanding, and the pursuit of higher ideals. By embracing our shared humanity and working together toward a brighter future, we realize the immense potential that lies within us.
                </p>
                <p className="mb-4">
                  ⚪ Peace is a fundamental right and strives for its preservation in all communities and nations.
                </p>
                <p className="mb-4">
                  ⚪ Love knows no borders and is a powerful force to bring us all together as humans.
                </p>
                <p className="mb-4">
                  ⚪ Kindness & compassion is our universal language, spoken and understood everywhere, regardless of race, religion, or nationality.
                </p>
                <p className="mb-4">
                  ⚪ Sharing is one of the aspects of our nature, and we understand the importance of giving and receiving.
                </p>
                <p className="mb-4">
                  ⚪ We embrace Mother Nature for providing the essential elements of human life to thrive, from the air we breathe to the water we drink and the food we eat. We take responsible actions to preserve its balance and sustainability for future generations.
                </p>
              </div>
            </div>

            <div className="flex items-center justify-center min-h-screen">
  <div className="w-full max-w-md bg-white p-6 rounded-lg shadow-md">
    <form onSubmit={handleOpenCaptchaDialog}>
      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="name">
          Name
        </label>
        <input
          type="text"
          name="name"
          placeholder="Enter your name"
          value={formData.name}
          onChange={handleChange}
          required
          className="block appearance-none w-full bg-white border border-gray-300 hover:border-gray-500 px-4 py-2 pr-8 rounded leading-tight focus:outline-none focus:shadow-outline"
        />
      </div>
      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
          Email
        </label>
        <input
          type="email"
          name="email"
          placeholder="example@example.com"
          value={formData.email}
          onChange={handleChange}
          required
          className="block appearance-none w-full bg-white border border-gray-300 hover:border-gray-500 px-4 py-2 pr-8 rounded leading-tight focus:outline-none focus:shadow-outline"
        />
      </div>
      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="country">
          Country
        </label>
        <select
          name="country"
          value={formData.country}
          onChange={handleCountryChange}
          required
          className="block appearance-none w-full bg-white border border-gray-300 hover:border-gray-500 px-4 py-2 pr-8 rounded leading-tight focus:outline-none focus:shadow-outline"
        >
          <option value="" disabled>Select Country</option>
          {countries.map((country, index) => (
            <option
              key={country.CountryID}
              value={country.CountryID}
              className={index % 2 === 0 ? 'bg-gray-200' : 'bg-white'} // Alternating colors
            >
              {country.CountryName}
            </option>
          ))}
        </select>
      </div>
      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="city">
          City
        </label>
        <select
          name="city"
          value={formData.city}
          onChange={handleCityChange}
          required
          className="block appearance-none w-full bg-white border border-gray-300 hover:border-gray-500 px-4 py-2 pr-8 rounded leading-tight focus:outline-none focus:shadow-outline"
        >
          <option value="" disabled>Select City</option>
          {cities.map((city, index) => (
            <option
              key={city.CityID}
              value={city.CityID}
              className={index % 2 === 0 ? 'bg-gray-200' : 'bg-white'} // Alternating colors
            >
              {city.CityName}
            </option>
          ))}
        </select>
      </div>
      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="zipcode">
          ZIP Code
        </label>
        <input
          type="text"
          name="zipcode"
          placeholder="Enter your ZIP code"
          value={formData.zipcode}
          onChange={handleChange}
          required
          className={`block appearance-none w-full bg-white border border-gray-300 hover:border-gray-500 px-4 py-2 pr-8 rounded leading-tight focus:outline-none focus:shadow-outline `}
        />
        
      </div>
      <div className="flex justify-center space-x-4">
        <button
          type="submit"
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
        >
          Light the Candle
        </button>
        <button
          type="button"
          onClick={handleCloseModal}
          className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
        >
          Cancel
        </button>
      </div>
    </form>
  </div>
</div>

          </div>
        </div>
    
       {/* Captcha Dialog */}
      {openCaptchaDialog && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-900 bg-opacity-50">
          <div className="bg-white p-8 rounded-lg shadow-md">
            <div className="mb-4">
              <h2 className="text-xl font-bold mb-4">Verify Captcha</h2>
              <p className="mb-2">Enter the following Captcha to proceed:</p>
              <div className="mb-2 text-center text-lg font-mono bg-black text-gray-300 py-2 px-4 rounded line-through">
                  {generatedCaptcha}
                </div>    
                <input
                type="text"
                value={captchaValue}
                onChange={handleCaptchaChange}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500 mb-4"
              />
               {captchaValue.toLowerCase() !== generatedCaptcha.toLowerCase() && captchaValue !== '' ? (
                  <p className="text-red-500 text-sm">Incorrect Captcha. Please try again.</p>
                ) : null }
            </div>
            <div className="flex items-center justify-between">
              <button
                onClick={generateCaptcha}
                className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline m-1"
              >
                Regenerate Captcha
              </button>
              <button
                onClick={() => {
                  if (captchaValue.toLowerCase() === generatedCaptcha.toLowerCase()) {
                    handleSubmitWithCaptcha();
                    setOpenCaptchaDialog(false); // Close dialog on successful captcha validation
                  } else {
                    toast.error('Incorrect Captcha. Please try again.');
                  }
                }}
                className={`bg-blue-500 hover:bg-blue-700 text-white font-bold m-1 py-2 px-4 rounded focus:outline-none focus:shadow-outline ${
                  captchaValue.toLowerCase() !== generatedCaptcha.toLowerCase() ? 'opacity-50 cursor-not-allowed' : ''
                }`}
                disabled={captchaValue.toLowerCase() !== generatedCaptcha.toLowerCase()}
              >
                Submit
              </button>
              <button
                onClick={handleCloseCaptchaDialog}
                className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
      

     
    </div>
  );
};

export default LightCandleForm;
