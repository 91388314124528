import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import GroupIntention from './pages/GroupIntention';
import AllIntentions from './pages/AllIntentions';
import LightACandle from './pages/LightACandle';


function App() {
  return (
    <Router>
      <Routes>
      <Route path="/" element={<GroupIntention />} />
      <Route path="/Allintentions" element={<AllIntentions />} />
      <Route path="/lightacandle" element={<LightACandle />} />
      </Routes>
    </Router>
  );
}

export default App;


