import React, { useState } from 'react';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faTimes } from '@fortawesome/free-solid-svg-icons';
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import GlobeComponent from '../components/GlobeComponent';
import Sidebar from '../components/Sidebar';
import OrganizeGroupIntention from '../pages/OrganizeGroupIntention';
import IndividualIntention from '../pages/IndividualIntention';
import LightCandleForm from '../components/LightACandleForm';

const LightACandle = () => {
  const [isOpen, setIsOpen] = useState(false);

  const [isOrganizeDialogOpen, setIsOrganizeDialogOpen] = useState(false);
  const [isIndividualDialogOpen, setIsIndividualDialogOpen] = useState(false);
  const [isCityDialogOpen, setIsCityDialogOpen] = useState(false); // New state for city dialog
  const [clickedCity, setClickedCity] = useState(null); // New state for storing clicked city data

  const [IsLightCandleDialogOpen, setIsLightCandleDialogOpen] = useState(false);

  const handleCloseModal = () => {
    setIsOpen(false);
    setIsLightCandleDialogOpen(false)
  };

  const handlePinModalClose = () => {
    setIsCityDialogOpen(false);
  };


  const handleOpenOrganizeDialog = () => {
    setIsOrganizeDialogOpen(true);
  };

  const handleOpenIndividualDialog = () => {
    setIsIndividualDialogOpen(true);
  };

  const handleCloseOrganizeDialog = () => {
    setIsOrganizeDialogOpen(false);
    setIsIndividualDialogOpen(false);
  };
  const handlePointClick = (point) => {
    setClickedCity(point);
    setIsCityDialogOpen(true);
  };

 
  const handleOpenLightacandleDialog=()=>{
    setIsLightCandleDialogOpen(true)
  }
  
  return (
    <div>
      <GlobeComponent onPointClick={handlePointClick} />
      
      {IsLightCandleDialogOpen&& (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-900 bg-opacity-50">
      <LightCandleForm handleCloseModal={handleCloseModal}/>
      </div>
      )}



      {!isOpen && (
        <Sidebar
          onOrganizeGroupClick={handleOpenOrganizeDialog}
          onIndividualIntentionClick={handleOpenIndividualDialog}
          onLightACandleClick={handleOpenLightacandleDialog}
        />
      )}
     

      <Modal
        isOpen={isCityDialogOpen}
        onRequestClose={handlePinModalClose}
        contentLabel="City Details"
        className="flex items-center justify-center h-screen"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50 "
      >
        <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-2xl mx-auto">
         
          {isCityDialogOpen && (
     
          <div className="relative">
          <button
                onClick={() => {
                  handlePinModalClose();
                }}
                className="absolute top-2 right-2 text-gray-500 hover:text-gray-700 focus:outline-none"
              >
                <FontAwesomeIcon icon={faTimes} size="2xl" />
          </button>
            <h1 className="text-xl font-bold mb-4">You clicked on: {clickedCity ? clickedCity.CityName : 'Unknown City'}</h1>
            <h2 className="text-xl font-bold mb-4">City Information</h2>
            
            <p className="text-lg font-semibold">CitName: {clickedCity.CityName}</p>
            <p className="text-lg font-semibold">Country: {clickedCity.CountryName}</p>
            <p className="text-lg font-semibold">Name: {clickedCity.name}</p>
            {/* <p className="text-lg font-semibold">Email: {clickedCity.email}</p>
            <p className="text-lg font-semibold">Zipcode: {clickedCity.zipcode}</p> */}

           
          </div>
       
      )}
        </div>
      </Modal>

      <ToastContainer />
    
      {isOrganizeDialogOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-900 bg-opacity-50">
          <OrganizeGroupIntention handleClose={handleCloseOrganizeDialog} />
        </div>
      )}
      {isIndividualDialogOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-900 bg-opacity-50">
          <IndividualIntention handleClose={handleCloseOrganizeDialog} />
        </div>
      )}

    </div>
    
  );
};

export default LightACandle;
